<template>
  <dash-page-new
      :title="filter ?  $t('lead_' + filter) : $t('All')"
      :subtitle="$t('Leads')"
      :root="$t('Dashboard')"
      :root-location="businessDashLink()"
      icon="mdi-card-account-phone-outline"
      :filters="view === 'table' ? filtersSelect : []"
      :filters-input="view === 'table' ? filters : {}"
      @filter="filters = $event"
      no-side>

    <template #header_action>
      <div class="d-flex align-center">
        <ws-tooltip :text="$t('HideFinishedLeads')">
          <ws-switch
              @input="initPage"
              v-model="hideCompleted"
          />
        </ws-tooltip>
        <v-btn-toggle mandatory v-model="$store.state.leads.view" background-color="transparent" :color="wsACCENT"   >
          <v-btn height="40"  text  class="noCaps font-weight-light" value="table">
            <v-icon small class="mr-1">mdi-table</v-icon>
            <h5>{{ $t('Table') }}</h5>
          </v-btn>
          <v-btn height="40"  text  class="noCaps font-weight-light" value="kanban">
            <v-icon small class="mr-1">mdi-view-dashboard-variant-outline</v-icon>
            <h5>{{ $t('Kanban') }}</h5>
          </v-btn>
        </v-btn-toggle>
        <v-btn height="40" @click="displayAddLead" class="noCaps ml-3" :color="wsATTENTION" dark  >
          <v-icon small>mdi-plus</v-icon>
          {{  $t('AddLead') }}
        </v-btn>

      </div>
    </template>
    <template #default>
      <div v-if="view === 'table'">
        <ws-data-table
            class="mt-6 "
            @select-item="selectedLead = $event"
            :row-action="displayEditLead"
            :items="leadsFiltered"
            :headers="headers"
            :search="filters.search"
            :context-actions-select="actionsSelect"
            :total="total"
            :load-more="loadMore"
        >

          <template #item.date="{item }">
            <div class="d-flex">
              <h5 :style="`color : ${wsACCENT}`" class=" text-no-wrap" >{{ PARSE_TIME_STAMP(item.date ,true ) }}</h5>
            </div>
          </template>

          <template #item.name="{item}">
            <h5> {{ item.name }}</h5>
          </template>

          <template #item.phone="{item}">
            <h5> {{ item.phone }}</h5>
          </template>
          <template #item.email="{item}">
            <ws-chip icon="mdi-email" :text="item.email"/>
          </template>
          <template #item.manager="{item}">
            <ws-chip icon="mdi-account-tie" :color="item.manager ? wsACCENT : 'grey'">
              <h5 class="font-weight-medium text-no-wrap" >{{ item.manager || $t('None')  }}</h5>
            </ws-chip>
          </template>

          <template #item.date_contacted="{item}">
            <h5 v-if="item.date_contacted" class="text-center text-no-wrap" >{{ PARSE_TIME_STAMP(item.date_contacted , true)  }} </h5>
            <h5 v-else class="grey--text text-center"> {{ $t('NotContacted') }}</h5>
          </template>

          <template #item.origin="{item}">
            <h5>{{ getOriginName(item.origin) }}</h5>
          </template>

          <template #item.status="{item}">
            <div class="d-flex ">
              <ft-select v-if="item.status !== 'converted'" @input="editLead($event, item)" v-model="item.status" :items="statusTypes">
                <h5 class="text-no-wrap" >
                  {{ $t("lead_" + item.status)   }}
                  <v-icon small>mdi-chevron-down</v-icon>
                </h5>

              </ft-select>
              <h5 v-else class="text-no-wrap" >
                {{ $t("lead_" + item.status)   }}
              </h5>
            </div>
          </template>

          <template #item.action="{item}">
            <ft-select
                :items="actionsSelect"
            >
              <v-btn @click="selectedLead = item" icon :color="wsATTENTION" >
                <v-icon>mdi-pencil-circle</v-icon>
              </v-btn>
            </ft-select>
          </template>





        </ws-data-table>

      </div>
      <v-sheet
          v-if="view === 'kanban' && total > leads.length "
          :style="`border : 1px solid ${wsBACKGROUND} !important`"
          :color="wsLIGHTCARD"
          class="pa-3 mt-3 wsRoundedHalf align-center justify-space-between"
          :class="[{'d-flex' : !SM}]"
          >
        <h5>{{ $t('RowsDisplayed') }} : {{ leads.length }} / {{ total }} </h5>
        <ws-button :class="[{'mt-2' : SM}]" :color="wsACCENT" text @click="loadMore(leads.length)" label="loadMore"></ws-button>
      </v-sheet>

      <div v-if="view === 'kanban'"
           style="height: 99%"
           class="overflow-auto overflow-y-auto height d-flex pt-6 px-2" >
        <v-sheet v-for="(items,status) in kanbanData" :key="status"
                 class=" mr-6"
                 min-width="270" width="270"
                 color="transparent"     >

          <div class="d-flex justify-space-between">
            <h4 class="font-weight-medium d-flex align-center">
              <v-icon :color="getStatusColor(status)" class="mr-2" small>{{ getStatusIcon(status) }}</v-icon>
              {{  $t("lead_" + status)  }}</h4>
            <v-chip outlined small> {{ getItemsByStatus(status).length}}  </v-chip>
          </div>

          <v-divider class="mt-3" />
          <draggable  v-model="kanbanData[status]"
                      group="status"
                      :forceFallback="true"
                      :empty-insert-threshold="120"
                      v-bind="opt"
                      @start="drag = true"
                      @change="changeDroppedStatus($event,status)"
                      @end=" drag = false">

            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
              <v-sheet  v-for="(item,i) in kanbanData[status]" :key="status + i "
                        :style="`border : 1px solid ${wsBACKGROUND}`"
                        style="cursor:grab;"
                        class="wsRoundedLight pa-3 mt-3"
                        min-width="270" width="270">

                <!-- Header-->
                <div class="d-flex justify-space-between">
                  <div>
                    <ws-chip icon="mdi-calendar" :color="wsACCENT">
                      <h5 class="font-weight-medium text-no-wrap" >{{ formatTime(item.date)  }}</h5>
                    </ws-chip>
                  </div>
                  <ft-select noRipple @input="editAction($event, item)" :value="item.status" :items="editActionTypes">
                    <v-btn small icon><v-icon small>mdi-dots-horizontal</v-icon></v-btn>
                  </ft-select>

                </div>
                <v-divider />
                <!-- Content-->
                <div class="d-flex justify-space-between mt-5">
                  <h5>{{  item.name }}</h5>
                  <div>
                    <ws-chip icon="mdi-phone" :text="item.phone"/>
                  </div>
                </div>
                <h6 v-if="originsSelect.length > 1" class="mt-2 font-weight-regular wsDARKER" >{{ getOriginName(item.origin) }}</h6>
                <h5 v-if="item.comment" class="font-weight-light mt-3 mb-3"> {{ item.comment }}</h5>
                <!-- Footer-->


                <div class="d-flex justify-space-between pt-3 mt-3">

                    <ws-chip v-if="item.manager" icon="mdi-account-tie" :color="item.manager ? wsACCENT : 'grey'">
                      <h5 class="font-weight-medium text-no-wrap" >
                        {{ item.manager }}
                      </h5>
                    </ws-chip>
                  <h5 v-if="item.date_contacted">{{ formatTime(item.date_contacted) }}</h5>

                </div>

              </v-sheet>
            </transition-group>

          </draggable>


        </v-sheet>
      </div>

    </template>
    <template #dialog>
      <!-- Edit Lead Dialog Window -->
      <ws-dialog
          width="700"
          v-model="displayDialog"
          :loading="LOADING"
          @save="newEntity ? addLead() : editLead(null, entityData )"
          :title="newEntity ? $t('AddLead') :  $t('EditLead')"
          :saveText="newEntity ? $t('Add') :  $t('Save')"
          :showDelete="!newEntity"
          @delete="deleteLead(entityData)"
      >

        <v-row no-gutters class="mt-3">
          <v-col cols="6" class="pr-2">
            <ws-text-field
                v-model="entityData.name"
                :label="$t('HumanName')"
                :placeholder="$t('HumanName')"
            />
          </v-col>
          <v-col cols="6" class="pl-2">
            <ws-select
                v-model="entityData.status"
                :items="statusTypes"
                :label="$t('Status')"
            />
          </v-col>
          <v-col cols="6" class="pr-1">
            <ws-phone-input
                v-model="entityData.phone"
                :label="$t('Phone')"
                hide-details
            />
          </v-col>
          <v-col cols="6" class="pl-2">
            <ws-text-field
                v-model="entityData.email"
                :label="$t('Email')"
                :placeholder="$t('EnterEmail')"
            />
          </v-col>
        </v-row>

        <ws-text-field
            class="mt-6"
            v-model="entityData.comment"
            :label="$t('Comment')"
            :placeholder="$t('Comment')"
            area
        />

      </ws-dialog>
      <!-- Turn to Client-->
      <ws-dialog
          v-if="displayConvertUserDialog"
          v-model="displayConvertUserDialog"
          :title="$t('LeadToClient')"
          @save="turnToClient"
          :save-text="$t('Proceed')"
      >

        <v-row no-gutters>

          <v-col cols="6" class="pr-2">
            <ws-text-field
                v-model="userEntityData.firstname"
                :placeholder="$t('Firstname')"
                :label="$t('Firstname')"
                :error="nameError"
                @input="nameError = false"
            />
          </v-col>
          <v-col cols="6" class="pl-2">
            <ws-text-field
                v-model="userEntityData.lastname"
                :placeholder="$t('Lastname')"
                :label="$t('Lastname')"
            />
          </v-col>

          <v-col cols="6" class="pr-2 mt-6">
            <ws-text-field
                v-model="userEntityData.password"
                :placeholder="$t('Password')"
                :label="$t('Password')"
                :error="passwordError"
                @input="passwordError = false"
            />
          </v-col>
          <v-col cols="6" class="pl-2 mt-6">
            <ws-text-field
                v-model="userEntityData.repeat_password"
                :placeholder="$t('PasswordRepeat')"
                :label="$t('PasswordRepeat')"
                :error="passwordError"
                @input="passwordError = false"
            />
          </v-col>
          <v-col cols="6" class="pr-2 mt-6">
            <ws-text-field
                v-model="userEntityData.email"
                :placeholder="$t('Email')"
                :label="$t('Email')"
                :error="emailError || userError"
                @input="emailError = false ; userError = false"
            />
          </v-col>
          <v-col cols="6" class="pl-2 mt-6">
            <ws-phone-input
                :label="$t('Phone')"
                :error="phoneError || userError"
                @input="phoneError=false ; userError = false "
                v-model="userEntityData.phone"
                hide-details
            />
          </v-col>
        </v-row>

      </ws-dialog>


      <!-- Delete Lead-->
      <ws-dialog
          :title="$t('DeleteLead')"
          display-confirm-delete
          close-delete
          :confirm-delete-text="$t('DeleteLeadText')"
          @delete="deleteLead(selectedLead)"
          v-model="displayDelete"
      />

    </template>

  </dash-page-new>
</template>

<script>
import {mapActions, mapState } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "BusinessDashboardLeads",
  components : {
    draggable
  },
  data() {
    return {
      total : null,
      hideCompleted : true,
      x : 0,
      y : 0,
      nameError : null,
      emailError : null,
      passwordError : null,
      phoneError : null,
      userError : null,
      userEntityData : {},
      filters : {},
      selectedLead : {},
      displayContextMenu : false,
      displayConvertUserDialog : false,
      displayDelete : false,
      entityData : {
        status : 'new',
        phone : ''
      },
      defaultEntityData : {
        status : 'new',
        phone : '',
      },
      displayDialog : false,
      newEntity: true,

      opt : {
        animation: 200,
        ghostClass: "ghost"
      },
      drag : false,
      leads : [],
      filter : 'new',
      kanbanViews : ['new', 'contacted', 'no_contact', 'in_process', 'success', 'refuse'],
      kanbanData :  {},
      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ ,

    }
  },
  computed : {
    ...mapState('leads', [ 'view']),

    originsSelect() {
      let distinctOrigins = [...new Set(this.leads.map(item => item.origin))];
      let items = []

      distinctOrigins.forEach(el => {
        items.push({ text : this.getOriginName(el) , value : el })
      })

      return items
    },
    filtersSelect(){
      let items =  [
        { text : this.$t('Status')  , value : 'status' , type : 'select' , items : this.filterTypes }
      ]

      if ( this.originsSelect.length > 1 ) {
        items.push({ text : this.$t('LeadOrigin')  , value : 'origin' , type : 'select' , items : this.originsSelect })
      }

      return items
    },

    actionsSelect() {
      let items =  [
        { text : this.$t('Open') , action : () => this.displayEditLead(this.selectedLead)  },
        { text : this.$t('LeadToClient') , action : this.openConvertToUser },
        { text : this.$t('Delete') , action : () => this.displayDelete = true }
      ]

      if (this.selectedLead.status === 'converted' ) {
        items.splice(1,1)
      }

      return items
    },
    leadsFiltered() {
      let items = this.leads
      if ( this.filters.status  ) {
        items = items.filter(el=> el.status === this.filters.status )
      }

      if ( this.filters.origin  ) {
        items = items.filter(el=> el.origin === this.filters.origin )
      }

      return items
    },
    headers() {
      let items =  [
        { text : this.$t('Date')    , value : 'date'     , width: 10, align : 'center' , sortable : false },
        { text : this.$t('HumanName')    , value : 'name'   },
        { text : this.$t('Phone')   , value : 'phone'    },
        { text : this.$t('Email')   , value : 'email'    },
        { text : this.$t('Manager') , value : 'manager', width: 10, align : 'center' },
        { text : this.$t('Changes') , value : 'date_contacted'     , width: 10, align : 'center' , sortable : false },
        { text : this.$t('Status')  , value : 'status', width: 10, align : 'center' },
       { value : 'action', width : 10, sortable : false }
      ]

      if ( this.originsSelect.length > 1 ) {
        items.splice(4, 0 , { text : this.$t('LeadOrigin')   , value : 'origin'    } )
      }

      return items
    },
    filterTypes() {
      // getItemsByStatus(status).length
      return [
        { text : this.$t("All")             + ` (${this.leads.length})`                                 , value : null ,       },
        { text : this.$t("lead_new")        + ` (${this.getItemsByStatus('new').length})`        , value : 'new'        },
        { text : this.$t("lead_contacted")  + ` (${this.getItemsByStatus('contacted').length})`  , value : 'contacted'  },
        { text : this.$t("lead_no_contact") + ` (${this.getItemsByStatus('no_contact').length})` , value : 'no_contact' },
        { text : this.$t("lead_in_process") + ` (${this.getItemsByStatus('in_process').length})` , value : 'in_process' },
        { text : this.$t("lead_success")    + ` (${this.getItemsByStatus('success').length})`    , value : 'success'    },
        { text : this.$t("lead_refuse")     + ` (${this.getItemsByStatus('refuse').length})`     , value : 'refuse'     },
      ]
    },
    statusTypes() {
      return [
        { text : this.$t("lead_new_single")        , value : 'new'        },
        { text : this.$t("lead_contacted")  , value : 'contacted'  },
        { text : this.$t("lead_no_contact")  , value : 'no_contact' },
        { text : this.$t("lead_in_process")  , value : 'in_process' },
        { text : this.$t("lead_success")    , value : 'success'    },
        { text : this.$t("lead_refuse")     , value : 'refuse'     },
      ]
    },
    editActionTypes() {
      return [
        { text : this.$t("Edit")  , value : 'edit' },
        { text : this.$t("Status")  , value : '' , children : this.statusTypes },
   //     { text : this.$t("Delete")  , value : 'delete' },
      ]
    },

    viewTypes() {
      return [
        { text : this.$t("Table") , value : 'table' },
        { text : this.$t("Kanban") , value : 'kanban' },
      ]
    }
  },
  watch : {

    'EVENTS_TRIGGER.BUSINESS_LEAD'() {
      this.initPage()
    },

    view(value) {
      if ( value === 'kanban') {
        this.initKanban()
      }
    },

  },
  methods : {
    ...mapActions('leads', [
      'ADD_BUSINESS_LEAD_ADMIN',
      'GET_BUSINESS_LEADS',
      'EDIT_BUSINESS_LEAD',
      'DELETE_BUSINESS_LEAD'
    ]),
    ...mapActions( 'crmSystem', [
      'ADD_CLIENT_PROFILE',
    ]),

    async loadMore(offset) {
      if ( (offset + 1) > this.leads.length && (offset < this.total) ) {

        let data = {
          offset : offset ,
          hide_completed : this.hideCompleted
        }
        let result = await this.GET_BUSINESS_LEADS(data)
        this.leads = [...this.leads , ...(result.leads || []) ]
        this.initKanban()
      }
    },
    getOriginName(origin) {
      if ( origin === 'dashboard' ) {
        return this.$t('AddedManually')
      } else  if ( origin === 'contact_form' ) {
        return this.$t('ContactForm')
      } else {
        return origin
      }
    },
    async turnToClient() {

      this.userEntityData.email = this.userEntityData.email.replace(/\s/g, "");


      if( this.userEntityData.email &&  !this.reg.test(this.userEntityData.email)  ) {
        this.emailError = true
        this.notify(this.$t('WrongEmail') , 'warning');
        return
      }
      if ( !this.userEntityData.password) {
        this.passwordError = true
        return this.notify(this.$t('EnterPassword') , 'warning')
      }
      if ( this.userEntityData.password !== this.userEntityData.repeat_password ) {
        this.passwordError = true
        return this.notify(this.$t('PasswordNotMatch') , 'warning')
      }
      if ( !this.userEntityData.phone) {
        this.phoneError = true
        return this.notify(this.$t('EnterPhone') , 'warning')
      }
      if ( !this.userEntityData.firstname) {
        this.nameError = true
        return this.notify(this.$t('EnterFirstname') , 'warning')
      }


      let result = await this.ADD_CLIENT_PROFILE(this.userEntityData)
      if ( !result ) {

        if ( this.$store.state.ajax.error === 'User already exist' ) {
          this.userError = true
          this.userError = true
          this.notify(this.$t('UserAlreadyExists') , 'warning')
        } else {
          this.notify(this.$t('Error'))
        }

        return
      }
      this.notify(this.$t('ClientProfileCreated') , 'success')
      await this.editLead('converted' , this.selectedLead)
      this.displayConvertUserDialog = false


    },

    openConvertToUser() {
      this.displayConvertUserDialog = true
      let password =  Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000;

      let name = this.selectedLead.name
      let lastname = ''
      let split = this.selectedLead.name.split(' ')
      if ( split.length > 1 ) {
        name = split[0]
        lastname = split[1]
      }

      this.userEntityData = {
        firstname : name,
        lastname : lastname,
        email : this.selectedLead.email,
        phone : this.selectedLead.phone,
        password : password,
        repeat_password : password
      }
    },
    editAction(value, item) {
      switch(value) {
        case 'delete' : this.deleteLead(item); break;
        case 'edit'   : this.displayEditLead(item); break;
        default : this.editLead(value, item);
      }
    },
    async addLead() {
      if ( !this.entityData.phone ) {
        return this.notify(this.$t('PhoneNumberEmpty'))
      }
      if ( !this.entityData.name ) {
        return this.notify(this.$t('NameEmpty'))
      }
      this.entityData.origin = 'dashboard'
      this.entityData.device = this.DEVICE

      let result = await this.ADD_BUSINESS_LEAD_ADMIN(this.entityData)
      if ( !result ) { return this.notify(this.$t('NetworkError'))}
      this.leads.push(result)
      this.initKanban()
      this.entityData = {}
      this.displayDialog = false
      this.notify(this.$t('LeadAdded'))
    },

    async editLead(status,item) {
      if (status) {
        item.status = status
      }
      let result = await this.EDIT_BUSINESS_LEAD(item)
      if ( !result ) { return this.notify(this.$t('NetworkError'))}
      let index = this.leads.findIndex(el=>el.uuid === result.uuid)
      if ( index !== -1) {
        this.leads[index] = result
        this.leads = JSON.parse(JSON.stringify(this.leads))
      }
      this.initKanban()

      this.displayDialog = false
      this.notify(this.$t('ChangesSaved'))
    },
    async deleteLead(item) {
      if ( !await this.DELETE_BUSINESS_LEAD(item.uuid) ) { return this.notify(this.$t('Error'))}
      let index = this.leads.findIndex(el=>el.uuid === item.uuid)
      if ( index !== -1) {
        this.leads.splice(index,1)
      }
      this.flushEntity()
      this.initKanban()
      this.displayDialog = false
      this.notify(this.$t('LeadDeleted'))
      this.displayDelete = false
    },
    changeDroppedStatus($event,status) {
      if ( $event.added ) {
        this.editLead(status,$event.added.element)
      }
    },

    displayAddLead() {
      this.flushEntity()
      this.displayDialog = true
      this.newEntity = true
    },
    displayEditLead(item) {
      this.entityData = JSON.parse(JSON.stringify(item))
      this.displayDialog = true
      this.newEntity = false
    },

    //technical
    initKanban() {
      this.kanbanData = {};
      this.kanbanViews.forEach((status)=>{
        this.kanbanData[status] = this.getItemsByStatus(status)
      })

    },
    getStatusColor(status) {
      switch(status) {
        case 'contacted' : return 'green lighten-1 '
        case 'success' : return 'green lighten-1'
        case 'in_process' : return 'green lighten-1 '
        case 'no_contact'  : return 'red lighten-1'
        case 'refuse'  : return 'red lighten-1'
        default : return this.wsACCENT
      }
    },
    getStatusIcon(status) {
      switch(status) {
        case 'new' : return 'mdi-alert-decagram'
        case 'contacted' : return 'mdi-phone'
        case 'no_contact' : return 'mdi-phone-cancel'
        case 'refuse' : return 'mdi-close'
        case 'success' : return 'mdi-check'
        case 'in_process' : return 'mdi-cogs'
        default : return 'mdi-cog'
      }
    },
    getItemsByStatus(status) {
      if (this.leads.length === 0 ) { return [] }
      return this.leads.filter(el=>el.status === status)
    },

    flushEntity() {
      this.entityData = JSON.parse(JSON.stringify(this.defaultEntityData))
    },

    async initPage() {
      let result = await this.GET_BUSINESS_LEADS({
        hide_completed : this.hideCompleted
      })
      this.leads = result.leads || [];
      this.total = result.leads_count;
      this.initKanban()
      await this.READ_EVENTS('business_lead')
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>